@charset "utf-8";
/* HIVELAB (http://www.hivelab.co.kr) */

/* NotoSans */
/* Roboto */

/* Reset */
#toast_header,
#toast_header div,
#toast_header ul,
#toast_header ol,
#toast_header li,
#toast_header h1,
#toast_header input,
#toast_header textarea,
#toast_header button,
#toast_header nav,
#toast_header label,
#toast_footer,
#toast_footer div,
#toast_footer dl,
#toast_footer dt,
#toast_footer dd,
#toast_footer ul,
#toast_footer ol,
#toast_footer li,
#toast_footer h1,
#toast_footer h2,
#toast_footer h3,
#toast_footer h4,
#toast_footer h5,
#toast_footer h6,
#toast_footer p,
#toast_footer table,
#toast_footer th,
#toast_footer td,
#toast_footer form,
#toast_footer fieldset,
#toast_footer legend,
#toast_footer textarea,
#toast_footer input,
#toast_footer select,
#toast_footer textarea,
#toast_footer button,
#toast_footer article,
#toast_footer aside,
#toast_footer section,
#toast_footer nav,
#toast_footer label,
#toast_footer caption {
  margin: 0;
  padding: 0;
}

#toast_header,
#toast_header nav,
#toast_footer {
  display: block;
}

#toast_header input,
#toast_header button,
#toast_header a,
#toast_footer input,
#toast_footer button,
#toast_footer a {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
}

html:lang(ja) #toast_header input,
html:lang(ja) #toast_header button,
html:lang(ja) #toast_header a,
html:lang(ja) #toast_footer input,
html:lang(ja) #toast_footer button,
html:lang(ja) #toast_footer a {
  font-family: 'Noto Sans JP', sans-serif;
}

html:lang(zh) #toast_header input,
html:lang(zh) #toast_header button,
html:lang(zh) #toast_header a,
html:lang(zh) #toast_footer input,
html:lang(zh) #toast_footer button,
#toast_footer a {
  font-family: 'Noto Sans SC', sans-serif;
}

#toast_header input,
#toast_header button,
#toast_footer input,
#toast_footer button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#toast_header li,
#toast_header img,
#toast_footer li,
#toast_footer img {
  border: 0;
  vertical-align: top;
}

#toast_header ul,
#toast_header ol,
#toast_footer ul,
#toast_footer ol {
  list-style: none;
}

#toast_header button,
#toast_footer button {
  overflow: visible;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

#toast_header button::-moz-focus-inner,
#toast_footer button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

#toast_header em,
#toast_footer em {
  font-style: normal;
}

#toast_header a,
#toast_header a:focus,
#toast_header a:active,
#toast_header a:hover,
#toast_footer a,
#toast_footer a:focus,
#toast_footer a:active,
#toast_footer a:hover {
  font-family: 'Noto Sans KR', sans-serif;
  color: inherit;
  text-decoration: none;
}

html:lang(ja) #toast_header a,
html:lang(ja) #toast_header a:focus,
html:lang(ja) #toast_header a:active,
html:lang(ja) #toast_header a:hover,
html:lang(ja) #toast_footer a,
html:lang(ja) #toast_footer a:focus,
html:lang(ja) #toast_footer a:active,
html:lang(ja) #toast_footer a:hover {
  font-family: 'Noto Sans JP', sans-serif;
}

html:lang(zh) #toast_header a,
html:lang(zh) #toast_header a:focus,
html:lang(zh) #toast_header a:active,
html:lang(zh) #toast_header a:hover,
html:lang(zh) #toast_footer a,
html:lang(zh) #toast_footer a:focus,
html:lang(zh) #toast_footer a:active,
html:lang(zh) #toast_footer a:hover {
  font-family: 'Noto Sans SC', sans-serif;
}

#toast_header .blind,
#toast_footer .blind {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 1px;
  line-height: 100px;
  white-space: nowrap;
}

#toast_header input::-webkit-contacts-auto-fill-button {
  display: none !important;
  visibility: hidden;
  position: absolute;
  right: 0;
  pointer-events: none;
}

#toast_header input::-ms-clear {
  display: none;
}

#toast_header input::-ms-reveal {
  display: none;
}

#toast_header *::-webkit-scrollbar {
  width: 14px;
  background-color: transparent;
}

#toast_header *::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 7px;
  background-clip: padding-box;
  background-color: rgba(150, 150, 150, 0.5);
}

#toast_header *::-webkit-scrollbar-track {
  padding: 2px;
  background-color: transparent;
  border-radius: 10px;
}

/* 디바이스 분기 핵 */
.is_m_show,
.is_m_ibshow {
  display: none !important;
}

.is_pc_show {
  display: block !important;
}

.is_pc_ibshow {
  display: inline-block !important;
}

/* 한국어 */
html:lang(ko) #toast_header,
html:lang(ko) #toast_footer {
  font-family: 'Noto Sans KR', sans-serif;
}

/* 일본어 */
html:lang(ja) #toast_header,
html:lang(ja) #toast_footer {
  font-family: 'Noto Sans JP', sans-serif;
}

/* 중국어 */
html:lang(zh) #toast_header,
html:lang(zh) #toast_footer {
  font-family: 'Noto Sans SC', sans-serif;
}

/* style common */
.fr {
  float: right;
}

#toast_header .side_menu_area .search_word_area .btn_search:after, #toast_header .gnb .lst_service_menu .is_new .link_txt .service_badge:after, #toast_header .gnb .lst_service_menu .is_update .link_txt .service_badge:after, #toast_footer .sitemap_wrap .lst_sns .link_sns, #toast_footer .family_site_wrap .btn_family_site:after {
  display: inline-block;
  overflow: hidden;
  background-image: url(https://static.toastoven.net/toast_dev/resources/css/../img/sp_gnb.png) , url(https://www.beta-nhncloud.com/resources/img/../img/sp_gnb.png) ;
  background-repeat: no-repeat;
  background-size: 150px auto;
  font-size: 10px;
  line-height: 999px;
}

/* header */
#toast_header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

#toast_header .inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1920px;
  min-width: 1440px;
  margin: 0 auto;
  padding: 1px 50px 0 60px;
  font-size: 0;
}

#toast_header .logo {
  display: inline-block;
  position: static;
  height: 69px;
  margin-right: 30px;
  vertical-align: middle;
  font-size: 0;
}

#toast_header .logo.service_logo {
  margin-right: 24px;
}

#toast_header .logo:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: '';
}

#toast_header .logo .link_txt {
  display: inline-block;
  vertical-align: middle;
}

#toast_header .logo .link_txt img.is_color {
  display: none;
}

#toast_header .logo .link_txt img.ngsc.is_color{
  display:none;
  idth:308px;
}

#toast_header .logo .link_txt img.is_white {
  display: block;
}

#toast_header .logo_img_m {
  display: none;
}

#toast_header .gnb {
  display: inline-block;
  vertical-align: middle;
}

#toast_header .gnb .gnb_scroll_wrap {
  margin-right: 130px;
}

#toast_header .gnb .lst_gnb {
  display: inline-block;
  white-space: nowrap;
}

#toast_header .gnb .lst_gnb .gnb_menu {
  display: inline-block;
  padding: 0 6px 3px;
  vertical-align: middle;
}

#toast_header .gnb .lst_gnb .link_menu {
  position: relative;
  z-index: 101;
}

#toast_header .gnb .lst_gnb .menu_txt {
  display: inline-block;
  position: relative;
  padding: 0 8px;
  line-height: 66px;
  font-size: 14px;
  color: #fff;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#toast_header .gnb .lst_gnb .link_menu.is_hover .menu_txt:after,
#toast_header .gnb .lst_gnb .link_menu.is_selected .menu_txt:after {
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  bottom: -3px;
  height: 3px;
  background-color: #125de6;
  content: '';
  -webkit-animation: fadein 0.5s;
          animation: fadein 0.5s;
}

#toast_header .gnb .lst_gnb .link_menu.is_selected .menu_txt:after {
  background-color: #fff;
  -webkit-animation-name: none;
          animation-name: none;
}

#toast_header.is_hover .gnb .lst_gnb .link_menu.is_selected .menu_txt:after {
  display: none;
}

#toast_header.is_hover .gnb .lst_gnb .link_menu.is_selected.is_hover .menu_txt:after {
  display: inline-block;
  background-color: #125de6;
}

#toast_header .gnb .lst_gnb .gnb_menu.is_point .link_menu {
  display: inline-block;
  height: 30px;
  margin-top: 2px;
  border-radius: 30px;
  background-color: #125de6;
  line-height: 30px;
  vertical-align: middle;
}

#toast_header .gnb .lst_gnb .gnb_menu.is_point .link_menu .menu_txt {
  padding: 0 14px;
  line-height: 30px;
}

#toast_header .gnb .lst_gnb .gnb_menu.is_point .link_menu.is_hover {
  background-color: #1446c8;
}

#toast_header .gnb .lst_gnb .gnb_menu.is_point .link_menu.is_hover .menu_txt:after {
  display: none;
}

#toast_header .gnb .lst_gnb .gnb_menu.is_point .link_menu.is_hover .menu_txt {
  color: #fff;
}

#toast_header .gnb .lst_gnb .gnb_menu.has_left_gap {
  margin-left: 14px;
}

#toast_header .side_menu_area {
  max-width: calc(100% - 790px);
  margin-top: 19px;
  margin-right: 1px;
  margin-left: auto;
  font-size: 0;
  white-space: nowrap;
}

#toast_header .side_menu_area .search_word_area {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: rgba(238, 238, 238, 0.7);
  vertical-align: top;
  -webkit-transition: all 1s;
  transition: all 1s;
}

#toast_header .side_menu_area .search_word_area .sizing_wrap {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
}

#toast_header .side_menu_area .search_word_area.is_active {
  width: 200px;
}

#toast_header.is_hover .side_menu_area .search_word_area.is_active:after,
#toast_header.is_fixed .side_menu_area .search_word_area.is_active:after {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  border: 1px solid #125de6;
  border-radius: 36px;
  content: '';
}

#toast_header .side_menu_area .search_word_area .btn_search {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 999px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}

#toast_header .side_menu_area .search_word_area .btn_search:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -7px 0 0 -7px;
  content: '';
}

#toast_header .side_menu_area .search_word_area .inp_search {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 4px 30px;
  border: 0;
  background-color: transparent;
  line-height: 22px;
  color: #333;
  font-size: 14px;
}

#toast_header .side_menu_area .search_word_area .inp_search::-webkit-input-placeholder {
  color: #999;
}

#toast_header .side_menu_area .search_word_area .inp_search::-moz-placeholder {
  color: #999;
}

#toast_header .side_menu_area .search_word_area .inp_search:-ms-input-placeholder {
  color: #999;
}

#toast_header .side_menu_area .search_word_area .btn_search_reset {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 30px;
  height: 30px;
  font-size: 7px;
  color: #777;
  line-height: 1;
}

#toast_header .side_menu_area .search_word_area.is_active .sizing_wrap {
  padding: 0;
}

#toast_header .side_menu_area .search_word_area.is_active .inp_search {
  display: block;
}

#toast_header .side_menu_area .search_word_area .inp_search:focus {
  outline: none;
}

#toast_header .side_menu_area .lang_select_area {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  padding-bottom: 18px;
  vertical-align: top;
}

#toast_header .side_menu_area .lang_select_area .btn_select_lang {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(232, 232, 232, 0.75);
  line-height: 1;
}

#toast_header .side_menu_area .lang_select_area .btn_select_lang:focus {
  -webkit-box-shadow: 0 0 0 1px #125de6;
          box-shadow: 0 0 0 1px #125de6;
}

#toast_header .side_menu_area .lang_select_area .btn_select_lang:hover {
  background-color: #125de6;
}

#toast_header .side_menu_area .lang_select_area .btn_select_lang:hover .select_lang_txt {
  color: #fff;
}

#toast_header .side_menu_area .lang_select_area .btn_select_lang:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: '';
}

#toast_header .side_menu_area .lang_select_area .select_lang_txt {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  color: #313338;
  font-weight: 400;
}

#toast_header .side_menu_area .lang_select_area .lst_lang {
  display: inline-block;
  visibility: hidden;
  position: absolute;
  top: 46px;
  right: -17px;
  left: -18px;
  padding: 9px 0;
  border: 1px solid rgba(184, 186, 195, 0.4);
  background-color: #fff;
  opacity: 0;
}

#toast_header .side_menu_area .lang_select_area .lst_lang.is_show {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#toast_header .side_menu_area .lang_select_area .btn_lang {
  display: block;
  padding: 0 13px;
  font-size: 12px;
  line-height: 30px;
  color: #313338;
  text-align: center;
}

#toast_header .side_menu_area .lang_select_area .lang_item .btn_lang:hover {
  background-color: #f1f3f6;
  color: #125de6;
  -webkit-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
}

#toast_header .side_menu_area .lst_member_link {
  display: inline-block;
  margin-left: 19px;
  vertical-align: middle;
}

#toast_header .side_menu_area .lst_member_link .link_item {
  display: inline-block;
  margin-left: 2px;
  vertical-align: middle;
}

#toast_header .side_menu_area .lst_member_link .link_txt {
  padding: 0 9px;
  font-size: 14px;
  line-height: 29px;
  color: #fff;
}

#toast_header .member_setting_area {
  display: inline-block;
  position: relative;
  max-width: 254px;
  margin: 6px 0 0 32px;
  padding-bottom: 22px;
  vertical-align: top;
}

#toast_header .member_setting_area .btn_member_setting {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #fff;
}

#toast_header .member_setting_area .lst_member_setting {
  display: inline-block;
  visibility: hidden;
  position: absolute;
  top: 42px;
  right: -20px;
  left: -20px;
  min-width: 160px;
  padding: 23px 0;
  border: 1px solid rgba(184, 186, 195, 0.4);
  background-color: #fff;
  opacity: 0;
}

#toast_header .member_setting_area .lst_member_setting .link_txt {
  display: block;
  padding: 0 20px;
  font-size: 12px;
  line-height: 28px;
  color: #313338;
  text-align: left;
}

#toast_header .member_setting_area .lst_member_setting .link_txt:hover {
  background-color: #f1f3f6;
  color: #125de6;
}

#toast_header .member_setting_area .lst_member_setting .setting_item {
  white-space: normal;
}

#toast_header .member_setting_area .lst_member_setting .setting_item.has_top_line:before {
  display: block;
  height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #ededed;
  content: '';
}

#toast_header .member_setting_area .lst_member_setting.is_show {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#toast_header.is_fixed {
  position: fixed;
  top: 0;
}

#toast_header.is_fixed,
#toast_header.is_hover {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

#toast_header.is_fixed .logo .link_txt img.is_white,
#toast_header.is_hover .logo .link_txt img.is_white {
  display: none;
}

#toast_header.is_fixed .logo .link_txt img.is_color,
#toast_header.is_hover .logo .link_txt img.is_color {
  display: block;
}

#toast_header.is_fixed .gnb .lst_gnb .menu_txt,
#toast_header.is_hover .gnb .lst_gnb .menu_txt {
  color: #313338;
}

#toast_header.is_fixed .gnb .is_point .link_menu .menu_txt,
#toast_header.is_hover .gnb .is_point .link_menu .menu_txt {
  color: #fff;
}

#toast_header.is_fixed .side_menu_area .lst_member_link .link_txt,
#toast_header.is_hover .side_menu_area .lst_member_link .link_txt {
  color: #313338;
}

#toast_header.is_fixed .side_menu_area .lst_member_link .link_txt.is_hover,
#toast_header.is_hover .side_menu_area .lst_member_link .link_txt.is_hover {
  color: #125de6;
}

#toast_header.is_fixed .member_setting_area .btn_member_setting,
#toast_header.is_hover .member_setting_area .btn_member_setting {
  color: #313338;
}

#toast_header .side_menu_area .lst_member_link .link_txt:hover {
  color: #125de6;
}

#toast_header .gnb .lst_gnb .link_menu.is_hover .menu_txt {
  color: #125de6;
}

#toast_header .gnb .lst_gnb .is_point .link_menu.is_hover .menu_txt {
  color: #fff;
}

#toast_header .member_setting_area {
  display: inline-block;
  position: relative;
  margin: 6px 0 0 32px;
  padding-bottom: 22px;
  vertical-align: top;
}

#toast_header .member_setting_area .btn_member_setting {
  display: inline-block;
  font-size: 14px;
  color: #fff;
}

#toast_header .member_setting_area .lst_member_setting {
  display: inline-block;
  visibility: hidden;
  position: absolute;
  top: 42px;
  right: -20px;
  left: -20px;
  padding: 23px 0;
  border: 1px solid rgba(184, 186, 195, 0.4);
  background-color: #fff;
  opacity: 0;
}

#toast_header .member_setting_area .lst_member_setting .link_txt {
  display: block;
  padding: 0 24px;
  font-size: 12px;
  line-height: 28px;
  color: #313338;
  text-align: left;
}

#toast_header .member_setting_area .lst_member_setting .link_txt:hover {
  background-color: #f1f3f6;
  color: #125de6;
}

#toast_header .member_setting_area .lst_member_setting .setting_item.has_top_line:before {
  display: block;
  height: 1px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #ededed;
  content: '';
}

#toast_header .member_setting_area .lst_member_setting.is_show {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#toast_header .side_menu_area .link_newsletter {
  display: inline-block;
  height: 30px;
  padding: 5px 15px;
  margin-left: 20px;
  border: 1px solid #fff;
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  vertical-align: top
}

#toast_header .side_menu_area .link_newsletter:focus {
  color: #fff
}

#toast_header.is_fixed .side_menu_area .link_newsletter,
#toast_header.is_hover .side_menu_area .link_newsletter,
#toast_header.is_fixed .side_menu_area .link_newsletter:focus,
#toast_header.is_hover .side_menu_area .link_newsletter:focus {
  border-color: #125de6;
  color: #125de6
}

#toast_header.is_fixed .side_menu_area .link_newsletter:hover,
#toast_header.is_hover .side_menu_area .link_newsletter:hover,
#toast_header.is_fixed .side_menu_area .link_newsletter:focus,
#toast_header.is_hover .side_menu_area .link_newsletter:focus {
  border-color: #0042c6;
  color: #0042c6
}


/* drop_menu_wrap */
#toast_header .gnb .drop_menu_wrap {
  display: block;
  visibility: hidden;
  position: absolute;
  top: 69px;
  right: 0;
  left: 0;
  padding: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  opacity: 0;
}

#toast_header .gnb .link_menu.is_hover + .drop_menu_wrap {
  visibility: visible;
  background-color: #fff;
  opacity: 1;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* toast.com service menu */
#toast_header .gnb .service_drop_menu .scroll_wrap {
  overflow: auto;
  max-height: calc(100vh - 110px);
  margin-left: 254px;
}

#toast_header .gnb .row_wrap.width_fixed {
  display: block;
  overflow: hidden;
}

#toast_header .gnb .row_wrap.width_fixed .col_group {
  display: block;
  float: left;
  width: 380px;
}

#toast_header .gnb .row_wrap {
  width: 1200px;
  margin: 0;
  padding: 24px 0 33px;
  border-top: 1px solid #ddd;
}

#toast_header .gnb .row_wrap:first-of-type {
  padding: 24px 0;
  border-top: 0;
}

#toast_header .gnb .row_wrap:after {
  display: block;
  clear: both;
  content: '';
}

#toast_header .gnb .lst_gnb .lst_gnb_sub {
  position: relative;
}

#toast_header .gnb .lst_gnb .intro_drop_menu {
  padding-left:180px
}

#toast_header .gnb .lst_gnb .solution_drop_menu {
  padding-left: 409px;
}

#toast_header .gnb .lst_gnb .pricing_drop_menu {
  padding-left: 476px;
}

#toast_header .gnb .lst_gnb .customer_drop_menu {
  padding-left: 530px;
}

#toast_header .gnb .lst_gnb .support_drop_menu {
  padding-left: 680px;
}

#toast_header .gnb .col_group {
  float: left;
  margin-right: 50px
}

#toast_header .gnb .col_group:last-child {
  margin-right: 0;
}

#toast_header .gnb .service_title {
  position: relative;
  display: block;
  width: 200px;
  margin-top: 16px;
  padding-top: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #222;
}

#toast_header .gnb .row_wrap .col_group .service_title:first-of-type,
#toast_header .gnb .row_wrap .col_group .service_title.is_m_show + .service_title {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}

#toast_header .gnb .lst_service_menu {
  width: 200px;
  margin-top: 9px;
}

#toast_header .gnb .lst_service_menu + .service_title:before {
  content: '';
  position: absolute;
  top: 0;
  left: auto;
  display: block;
  width: 190px;
  height: 1px;
  background-color: rgba(221, 221, 221, 0.3);
}

#toast_header .gnb .lst_service_menu.collapse:not(.show) {
  display: block;
}

#toast_header .gnb .lst_service_menu .service_menu + .service_menu {
  margin-top: 8px;
}

#toast_header .gnb .lst_service_menu .service_menu + .service_menu {
  margin-top: 6px;
}

#toast_header .gnb .lst_service_menu .link_txt {
  display: inline-block;
  width: 150px;
  line-height: 22px;
  font-size: 13px;
  font-weight: 400;
  color: #555;
  white-space: normal;
}

#toast_header .gnb .lst_service_menu .link_txt:hover {
  color: #222;
  text-decoration: underline;
}

#toast_header .gnb .lst_service_menu .link_txt .service_badge {
  position: relative;
  display: inline-block
}

#toast_header .gnb .lst_service_menu .is_new .link_txt .service_badge:after, #toast_header .gnb .lst_service_menu .is_update .link_txt .service_badge:after {
  position: absolute;
  top: 50%;
  height: 14px;
  margin-top: -12px;
  margin-left: 4px;
  vertical-align: middle;
}

#toast_header .gnb .lst_service_menu .is_new .link_txt .service_badge:after {
  width: 29px;
  background-position: 0 -50px;
  content: 'new';
}

#toast_header .gnb .lst_service_menu .is_update .link_txt .service_badge:after {
  width: 45px;
  background-position: -31px -50px;
  content: 'update';
}

#toast_header .gnb .lst_service_menu .is_new .link_txt .service_badge:after {
  width: 29px;
  background: url(https://static.toastoven.net/toast_dev/resources/css/../img/sp_common.png)  no-repeat -55px -36px, url(https://www.beta-nhncloud.com/resources/img/../img/sp_common.png)  no-repeat -55px -36px;
  background-size: 250px auto;
  content: 'new'
}

#toast_header .gnb .lst_service_menu .is_update .link_txt .service_badge:after {
  width: 45px;
  background: url(https://static.toastoven.net/toast_dev/resources/css/../img/sp_common.png)  no-repeat -86px -36px, url(https://www.beta-nhncloud.com/resources/img/../img/sp_common.png)  no-repeat -86px -36px;
  background-size: 250px auto;
  content: 'update'
}

#toast_header .gnb .gnb_sub_menu {
  display: inline-block;
}

#toast_header .gnb .gnb_sub_menu .menu_txt {
  padding: 0 20px;
  color: #313338;
}

#toast_header .gnb .lst_gnb .support_drop_menu.gov {
  padding-left: 511px;
}

/* footer */
#toast_footer {
  min-width: 1440px;
  background-color: #222;
  font-size: 0;
}

#toast_footer .sitemap_wrap .inner {
  max-width: 1920px;
  margin: 0 auto;
  padding: 50px 0 57px 60px;
}

#toast_footer .link_logo {
  display: block;
  width: 100%;
  height: 100%;
}

#toast_footer .sitemap_wrap .logo_toast {
  display: inline-block;
  vertical-align: middle;
}

#toast_footer .sitemap_wrap .lst_sns {
  display: inline-block;
  margin-left: 27px;
  vertical-align: middle;
}

#toast_footer .sitemap_wrap .lst_sns .item_sns {
  display: inline-block;
  margin-left: 10px;
}

#toast_footer .sitemap_wrap .lst_sns .item_sns:first-of-type {
  margin-left: 0;
}

#toast_footer .sitemap_wrap .lst_sns .link_sns {
  width: 30px;
  height: 30px;
}

#toast_footer .sitemap_wrap .lst_sns .ico_twitter {
  background-position: -32px -18px;
}

#toast_footer .sitemap_wrap .lst_sns .ico_facebook {
  background-position: 0 -18px;
}

#toast_footer .sitemap_wrap .lst_sns .ico_youtube {
  background-position: -79px 0;
}

#toast_footer .sitemap_wrap .lst_sns .ico_blog {
  background-position: -111px 0;
}

#toast_footer .sitemap_wrap .lst_sitemap {
  margin-top: 44px;
}

#toast_footer .sitemap_wrap .lst_sitemap .col_group {
  display: inline-block;
  min-width: 170px;
  vertical-align: top;
}

#toast_footer .sitemap_wrap .lst_sitemap .col_group + .col_group {
  margin-left: 60px;
}

#toast_footer .sitemap_wrap .lst_sitemap .is_col_merge2 .col_group {
  min-width: 170px;
}

#toast_footer .sitemap_wrap .lst_sitemap .title_txt,
#toast_footer .sitemap_wrap .lst_sitemap .title_txt .link_title {
  font-size: 14px;
  line-height: 16px;
  color: #fafafa;
}

.lst_sitemap_detail + .title_txt {
  display: block;
  margin-top: 40px;
}

#toast_footer .sitemap_wrap .lst_sitemap .lst_sitemap_detail {
  display: block;
  min-height: 77px;
  margin-top: 26px;
}

#toast_footer .sitemap_wrap .lst_sitemap .lst_sitemap_detail.collapsing {
  height: auto;
  -webkit-transition: unset;
  transition: unset;
}

#toast_footer .sitemap_wrap .lst_sitemap .title_txt + .lst_sitemap_detail {
  margin-top: 15px;
}

#toast_footer .sitemap_wrap .lst_sitemap .link_item {
  margin-top: 7px;
}

#toast_footer .sitemap_wrap .lst_sitemap .link_item:first-of-type {
  margin-top: 0;
}

#toast_footer .sitemap_wrap .lst_sitemap .link_txt {
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  color: #ccc;
  letter-spacing: normal;
  white-space: normal;
}

#toast_footer .sitemap_wrap .lst_sitemap .link_txt:hover {
  color: #4281f3;
  -webkit-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
}

#toast_footer .nhn_footer {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

#toast_footer .nhn_footer .inner {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  padding: 48px 60px 54px 260px
}

#toast_footer .nhn_footer .logo_nhn {
  display: inline-block;
  position: absolute;
  top: 38px;
  left: 60px;
  width: 153px;
  height: 48px;
}

#toast_footer .nhn_footer .lst_company_link {
  display: inline-block;
  margin-left: -16px;
}

#toast_footer .nhn_footer .lst_company_link .item_link {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  line-height: normal;
}

#toast_footer .nhn_footer .lst_company_link .item_link:before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 13px;
  margin-top: -6px;
  background-image: none;
  background-color: rgba(255, 255, 255, 0.08);
  content: '';
}

#toast_footer .nhn_footer .lst_company_link .item_link:first-of-type:before {
  display: none;
}

#toast_footer .nhn_footer .lst_company_link .link_txt {
  padding: 0 15px 0 16px;
  font-size: 14px;
  font-weight: 400;
  color: #777;
}

#toast_footer .nhn_footer .lst_company_link .link_txt:hover {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: underline;
  -webkit-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
}

#toast_footer .nhn_footer .lst_company_link .point_txt {
  color: #fff;
}

#toast_footer .nhn_footer .family_site_wrap {
  float: right;
  position: relative;
  margin-top: -9px;
}

#toast_footer .nhn_footer .family_site_wrap .btn_family_site {
  width: 170px;
  height: 40px;
  padding: 0 20px;
  background-color: #2f2f2f;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  letter-spacing: normal;
}

#toast_footer .nhn_footer .family_site_wrap .btn_family_site .btn_txt {
  opacity: 0.77;
  font-family: arial;
  color: #777;
}

#toast_footer .nhn_footer .family_site_wrap .btn_family_site:after {
  position: absolute;
  top: 50%;
  right: 13px;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  background-position: -64px -18px;
  content: '';
}

#toast_footer .nhn_footer .family_site_wrap .lst_family_site {
  display: none;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 40px;
  z-index: 1;
  padding: 8px 0;
  background-color: #2f2f2f;
}

#toast_footer .nhn_footer .family_site_wrap.is_active .lst_family_site {
  display: block;
}

#toast_footer .nhn_footer .family_site_wrap.is_active .btn_family_site:after {
  background-position: -64px -34px;
}

#toast_footer .nhn_footer .family_site_wrap.is_active .btn_family_site .btn_txt {
  color: #ccc;
}

#toast_footer .nhn_footer .family_site_wrap .lst_family_site .link_txt {
  display: block;
  position: relative;
  padding: 0 20px;
  font-size: 14px;
  line-height: 32px;
  color: #777;
  font-family: arial;
  white-space: nowrap;
}

#toast_footer .nhn_footer .family_site_wrap .lst_family_site .link_txt:hover {
  color: #ccc;
  -webkit-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
}

#toast_footer .nhn_footer .family_site_wrap .lst_family_site .has_top_line {
  position: relative;
}

#toast_footer .nhn_footer .family_site_wrap .lst_family_site .has_top_line .link_txt:before {
  display: block;
  height: 1px;
  margin: 10px 0;
  background-color: #555;
  content: '';
}

#toast_footer .nhn_footer .notice_txt {
  margin-top: 16px;
  font-size: 13px;
  color: #555;
  line-height: 20px;
}

#toast_footer .nhn_footer .lst_company_info {
  margin-top: 18px;
}

#toast_footer .nhn_footer .lst_company_info .info_item {
  display: inline-block;
  height: auto;
  margin-right: 3px;
  font-size: 13px;
  line-height: 20px;
  font-weight: normal;
  color: #555;
  letter-spacing: -0.005em;
}

#toast_footer .nhn_footer .lst_company_info .info_item:nth-child(3) {
  display: block;
}

#toast_footer .nhn_footer .lst_company_info .link_txt {
  font-size: 13px;
  text-decoration: underline;
}

#toast_footer .nhn_footer .copyright_txt {
  margin-top: 23px;
  font-size: 12px;
  font-weight: normal;
  color: #555;
  letter-spacing: -0.02em;
}

#toast_footer .nhn_footer.logo_none .logo_link {
  display: none
}

#toast_footer .nhn_footer.logo_none .inner {
  padding: 48px 60px 54px
}

.noti_browser .btn_close_noti:before, .gnb .lst_service_menu .is_new .link_txt:after, .gnb .lst_service_menu .is_update .link_txt:after, .widget .btn_widget:before, .noti_wrap .noti_group .btn_more, .lst_service .is_new .service_name:after, .lst_service .is_update .service_name:after, .service_content .lst_service .service_name .service_badge, .lst_partner .btn_detail:after, .lst_contact .h_txt:before, .lst_contact .btn_inquiry:after, .service_group .service_icon, .location .breadcrumb-item:before, .lst_customer_case .case_title .link_txt:after, .modal .btn_close:before, .lst_contact .contact_item i, .cover_wrap .btn_search:before, .carousel_certification .carousel-control-prev-icon, .carousel_certification .carousel-control-next-icon, .lst_form_wrap .lst_noti .noti_item.is_warning:before, .pop_notice .slick-prev, .pop_notice .slick-next, .solution_content .btn_detail:after, .tbl_credit .is_warning .ico_warning, .marketplace_content .btn_detail:after, .game_kr .btn_detail:after {
  display: inline-block;
  overflow: hidden;
  background-image: url(https://static.toastoven.net/toast_dev/resources/css/../img/sp_common.png) , url(https://www.beta-nhncloud.com/resources/img/../img/sp_common.png) ;
  background-repeat: no-repeat;
  background-size: 500px auto;
  font-size: 10px;
  line-height: 999px
}

.noti_browser {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
  height: 30px;
  background: ; background-color: #f1c21b;
  font-size: 0;
  text-align: center
}

.noti_browser:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: ''
}

.noti_browser .noti_txt {
  display: inline-block;
  font-size: 12px;
  color: #222;
  letter-spacing: -.1px;
  vertical-align: middle
}

.noti_browser .inp_check_wrap {
  display: inline-block;
  margin-left: 30px
}

#toast_header .noti_browser .inp_check {
  display: inline-block;
  margin: 0 5px 0 0;
  vertical-align: middle;
  -webkit-appearance: checkbox
}

.noti_browser .lb_txt {
  display: inline-block;
  position: relative;
  font-size: 12px;
  color: #222;
  letter-spacing: -.1px;
  vertical-align: middle
}

.noti_browser .btn_close_noti {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding: 8px;
  background: none;
  font-size: 0;
  line-height: 0
}

.noti_browser .btn_close_noti:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  background-position: -76px 0;
  content: ''
}

@media all and (max-width: 1200px) {
  #toast_header {
    min-width: 1200px;
  }

  #toast_header .side_menu_area .search_word_area.is_active {
    max-width: 159px;
  }

  #toast_header .gnb .service_drop_menu {
    width: 100vw;
  }

  /* toast.com */
  #toast_header .gnb .service_drop_menu .scroll_wrap {
    margin-left: 228px;
  }

  #toast_header .gnb .service_drop_menu .row_wrap {
    min-width: 1200px;
  }
}

@media all and (max-width: 1920px) {
  #toast_footer .sitemap_wrap .lst_sitemap .col_group + .col_group {
    margin-left: 26px
  }
}

@media all and (max-width: 1620px) {
  #toast_footer .sitemap_wrap .lst_sitemap .col_group + .col_group {
    margin-left: 0;
  }
}

.noti_browser .btn_close_noti:before, .gnb .lst_service_menu .is_new .link_txt .service_badge:after, .gnb .lst_service_menu .is_update .link_txt .service_badge:after, .widget .btn_widget:before, .noti_wrap .noti_group .btn_more, .lst_service .is_new .service_name:after, .lst_service .is_update .service_name:after, .lst_partner .btn_detail:after, .lst_contact .h_txt:before, .lst_contact .btn_inquiry:after, .service_group .service_icon, .location .breadcrumb-item:before, .lst_customer_case .case_title .link_txt:after, .modal .btn_close:before, .lst_contact .contact_item i, .cover_wrap .btn_search:before, .carousel_certification .carousel-control-prev-icon, .carousel_certification .carousel-control-next-icon, .lst_form_wrap .lst_noti .noti_item.is_warning:before, .pop_notice .slick-prev, .pop_notice .slick-next, .solution_content .btn_detail:after, .tbl_credit .is_warning:before {
  display: inline-block;
  overflow: hidden;
  background-image: url(https://static.toastoven.net/toast_dev/resources/css/../img/sp_common.png) , url(https://www.beta-nhncloud.com/resources/img/../img/sp_common.png) ;
  background-repeat: no-repeat;
  background-size: 500px auto;
  font-size: 10px;
  line-height: 999px
}

.noti_browser {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
  height: 30px;
  background: ; background-color: #f6bf00;
  font-size: 0;
  text-align: center;
}

.noti_browser:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: '';
}

.noti_browser .noti_txt {
  display: inline-block;
  font-size: 12px;
  color: #222;
  letter-spacing: -0.1px;
  vertical-align: middle;
}

.noti_browser .inp_check_wrap {
  display: inline-block;
  margin-left: 30px;
}

.noti_browser .inp_check {
  display: inline-block;
  margin: 0 5px 0 0;
  vertical-align: middle;
  -webkit-appearance: checkbox;
}

.noti_browser .lb_txt {
  display: inline-block;
  position: relative;
  font-size: 12px;
  color: #222;
  letter-spacing: -0.1px;
  vertical-align: middle;
}

.noti_browser .btn_close_noti {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding: 8px;
  background: none;
  font-size: 0;
  line-height: 0;
}

.noti_browser .btn_close_noti:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  background-position: -76px 0;
  content: '';
}

/* gnb > outlink */
.lst_gnb_sub .gnb_sub_menu .btn_out_link {
  display: inline-block;
  position: relative;
  padding-right:28px;
  vertical-align: middle;
}

.lst_gnb_sub .gnb_sub_menu .btn_out_link:after {
  content:'';
  display: inline-block;
  overflow:hidden;
  position: relative;
  top:1px;
  margin-left:10px;
  width:12px;
  height:12px;
  background: url(/resources/img/sp_gnb.758fff6aacf3817c4d01ef8bbfc866b0.png) no-repeat -111px -34px;
}
